export const USER_SAVE_USER = 'USER_SAVE_USER'; // 保存用户信息

export const USER_IS_USER = 'USER_IS_USER'; // 判断用户是否填写过年龄和性别

export const HOME_SET_VISIBLE = 'HOME_SET_VISIBLE'; // 打开模态框

export const DETAIL_SET_DATA = 'DETAIL_SET_DATA'; // 设置详情数据

export const HOME_SET_BUYVISIBLE = 'HOME_SET_BUYVISIBLE'; // 设置是否展示购买页

export const HOME_SET_QRCODE = 'HOME_SET_QRCODE'; // 设置支付宝code码

export const HOME_SET_PULLTIMER = 'HOME_SET_PULLTIMER'; // 设置轮询定时器
