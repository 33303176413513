import * as TYPES from '@/constants/actionTypes';

const defaultState = {
  userList: {
    token: '',
    userPic: '',
    userType: 0,
    userId: ''
  },
  isUser: false
}

export default function home(state = defaultState, action) {
  switch (action.type) {
    case TYPES.USER_SAVE_USER:
      return {
        ...state,
        userList: JSON.parse(JSON.stringify(action.payload))
      }
    case TYPES.USER_IS_USER:
      return {
        ...state,
        isUser: action.payload
      }
    
    default:
      return state
  }
}