import * as TYPES from '@/constants/actionTypes';

const defaultState = {
  detailData: {}
}

export default function home(state = defaultState, action) {
  switch (action.type) {
    case TYPES.DETAIL_SET_DATA:
      return {
        ...state,
        detailData: action.payload
      }
    default:
      return state
  }
}