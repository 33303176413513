import {
  createStore,     
  compose,         
  combineReducers, 
  applyMiddleware, 
} from 'redux';
import promise from 'redux-promise';    
import thunk from 'redux-thunk';


import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'; 

import { home, user, detail } from '@/reducer'; 


const rootPersistConfig = {
  key: 'reduxData',
  storage,
  stateReconciler: autoMergeLevel2,

  whitelist: ['home', 'user', 'detail'],  
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose  


const myPersistReducer = persistReducer(
  rootPersistConfig,
  combineReducers({
    home,
    user,
    detail
  })
)

const store = createStore(
  myPersistReducer,
  composeEnhancers(applyMiddleware(thunk, promise))
)

export { store }
