import * as TYPES from '@/constants/actionTypes';

const defaultState = {
  visible: false,
  buyVisible:false,
  qrCode:"",
  pullTimer:null
}

export default function home(state = defaultState, action) {
  switch (action.type) {
    case TYPES.HOME_SET_VISIBLE:
      return {
        ...state,
        visible: action.payload
      }
    case TYPES.HOME_SET_BUYVISIBLE:
      return {
        ...state,
        buyVisible: action.payload
      }
    case TYPES.HOME_SET_QRCODE:
      return {
        ...state,
        qrCode: action.payload
      }
    case TYPES.HOME_SET_PULLTIMER:
      return {
        ...state,
        pullTimer: action.payload
      }
    default:
      return state
  }
}