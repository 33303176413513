import React, { Component } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { saveUser } from '@/actions/user';

import { setBuyVisible } from '@/actions/home';
import { connect } from 'react-redux';
import _ from 'loadsh';
import { withRouter } from 'react-router-dom';

export default @withRouter
@connect(state => {
  return {
    userList: state.user.userList,
    buyVisible: state.home.buyVisible,
  }
}, {
  saveUser,
  setBuyVisible
})

class extends Component {
  constructor (props) {
    super(props)
    
    let cancelToken = axios.CancelToken;
    const cancel = []
    const removePending = config => {
      for(let p in cancel){
        if (cancel[p].u === config.url) {
          cancel[p].f()
        }
      }
    }

    axios.interceptors.request.use(config => {
      config.cancelToken = new cancelToken(c => {
        cancel.push({ 
          f: c,
          u: config.url,
        })
      })
      return config
    }, error => {
      return Promise.reject(error)
    })

    // 添加响应拦截器
    axios.interceptors.response.use(response => {
      // 根据状态码跳转
      if(response.data.code === 401) {
        this.props.history.push('/');
        this.props.saveUser({
          ...this.props.userList,
          userType: 0
        })
        this.props.setBuyVisible(false);
      }
    
      return response;
    }, error => {

      switch (error.code) {
        case 401:
          // window.location.href = 'https://www.baidu.com'
          break;
        
        default:
          break;
      }
      return Promise.reject(error);
    });
  }

  render () {
    return <></>
  }
}

