import * as TYPES from '@/constants/actionTypes';

// 注册模态框
export const setVisible = option => {
  return {
    type: TYPES.HOME_SET_VISIBLE,
    payload: option
  }
}
export const setBuyVisible = option => {
  return {
    type: TYPES.HOME_SET_BUYVISIBLE,
    payload: option
  }
}
export const setQrCode = option => {
  return {
    type: TYPES.HOME_SET_QRCODE,
    payload: option
  }
}
export const setPullTimer = option => {
  return {
    type: TYPES.HOME_SET_PULLTIMER,
    payload: option
  }
}