import {
  Home,
  Layout,
  Feature,
  ProductAll,
  ProductDetail,
  Person,
  FeatureMathematics,
  FeatureLanguage,
  QrCodeInterim,
  ProductTotal,
} from './assembly';

const routes = [
  { path: "/qr_code_interim", component: QrCodeInterim },
  {
    path: "/",
    component: Layout,
    children: [
      { path: "/feature", component: Feature },
      { path: "/product_total", component: ProductTotal },
      { path: "/feature_mathematics", component: FeatureMathematics },
      { path: "/feature_language", component: FeatureLanguage },
      { path: "/productAll", component: ProductAll },
      { path: "/product_detail", component: ProductDetail },
      { path: "/person", component: Person },
      { path: "/", component: Home },
    ]
  },
]

export default routes;