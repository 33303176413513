import loadable from '@/utils/loadable.js'

const Layout = loadable(() => import('@/layout'));
const Home = loadable(() => import('@/pages/home'));  //首页
const Feature = loadable(() => import('@/pages/feature'));  //产品特色
const ProductAll = loadable(() => import('@/pages/productAll'));  //所有产品
const ProductDetail = loadable(() => import('@/pages/productDetail'));  // 产品详情页
const Person = loadable(() => import('@/pages/person'));  // 个人中心

const FeatureMathematics = loadable(() => import('@/pages/feature_mathematics'));  //产品特色 -- 青蛙数学
const FeatureLanguage = loadable(() => import('@/pages/feature_language'));  //产品特色 -- 熊熊语文

const QrCodeInterim = loadable(() => import('@/pages/qr_code_interim'));  //二维码下载过渡页
const ProductTotal = loadable(() => import('@/pages/productTotal'));  //

export {
  Home,
  Layout,
  Feature,
  ProductAll,
  ProductDetail,
  Person,
  FeatureMathematics,
  FeatureLanguage,
  QrCodeInterim,
  ProductTotal,
}