import * as TYPES from '@/constants/actionTypes';

// 保存用户数据
export const saveUser = option => {
  return {
    type: TYPES.USER_SAVE_USER,
    payload: option
  }
}

// 判断用户是否填写过年龄和性别
export const isUser = option => {
  return {
    type: TYPES.USER_IS_USER,
    payload: option
  }
}